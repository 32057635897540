import React from 'react';
import styled from 'styled-components';

const HelpCenterLink = styled.a`
  display: inline-block;
  color: #13264d;
  padding: 8px 16px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: bold;
`;

export default function HeaderHelpCenter() {
  return (
    <HelpCenterLink href="https://oncloud.kb.help/" target="_blank" rel="noopener noreferrer">
      Help Center
    </HelpCenterLink>
  );
}
