import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { TManageAccounts } from '@app/types/manageAccounts';
import StatusBadge from '@app/components/apps/alerts/StatusBadge';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import ManageAccountsForm from '@app/components/apps/manage-accounts/ManageAccountsForm';
import useManageAccountsForm from '@app/components/apps/manage-accounts/useManageAccountsForm';
import { useCreateAccount, useUpdateAccount, useScanAccount } from '@app/api/account.api';

import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { getEnumValue } from '@app/services/enum.service';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import InviteAccountModal from '@app/components/apps/manage-accounts/InviteAccountModal';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { differenceInHours, parse } from 'date-fns';

const manageAccountsTableCols: TDataColumns<TManageAccounts> = [
  {
    title: 'Name',
    dataIndex: 'name',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Creation Time',
    dataIndex: 'creationTime',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'AccountStatus',
    showSortDirections: true,
    render: (val, record) => (
      <StatusBadge
        type={
          (record.status as any) === 'Enabled'
            ? 'enabled'
            : (record.status as any) === 'Disabled'
            ? 'disabled'
            : 'pending'
        }
      >
        {record.status}
      </StatusBadge>
    ),
  },
  {
    title: 'Microsoft Application Status',
    dataIndex: 'microsoftApplicationStatus',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'MicrosoftApplicationStatus',
    showSortDirections: true,
    render: (val, record) => (
      <StatusBadge type={(record?.microsoftApplicationStatus as any) === 'Active' ? 'enabled' : 'disabled'}>
        {record.microsoftApplicationStatus}
      </StatusBadge>
    ),
  },
  {
    title: 'Last Scan',
    dataIndex: 'lastScanTimeEnd',
    allowFiltering: true,
    type: 'datetime',
    showSortDirections: true,
    render: (val, record) => (
      <StatusBadge
        type={
          record?.lastScanTimeEnd == null ||
          differenceInHours(new Date(), parse(record?.lastScanTimeEnd, 'dd/MM/yyyy HH:mm', new Date())) > 4
            ? 'disabled'
            : 'enabled'
        }
      >
        {record?.lastScanTimeEnd || 'None'}
      </StatusBadge>
    ),
  },
  {
    title: 'Error Details',
    dataIndex: 'microsoftConnectorError',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    hideColumn: true,
  },

  {
    title: 'Owner Email',
    dataIndex: 'ownerEmail',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
  },
  {
    title: 'Custom Security Score',
    dataIndex: 'customSecurityScore',
    allowFiltering: true,
    type: 'number',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Baseline Security Score',
    dataIndex: 'standardSecurityScore',
    allowFiltering: true,
    type: 'number',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Last Updated Time',
    dataIndex: 'lastUpdatedTime',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Last Scan Time',
    dataIndex: 'lastScanTime',
    allowFiltering: true,
    type: 'datetime',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Microsoft Tenant ID',
    dataIndex: 'microsoftTenantId',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Microsoft Tenant Name',
    dataIndex: 'microsoftTenantName',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Microsoft Application ID',
    dataIndex: 'microsoftApplicationId',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Is Scanning',
    dataIndex: 'isScannign',
    allowFiltering: true,
    type: 'boolean',
    showSortDirections: true,
    hideColumn: true,
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    allowFiltering: false,
    type: 'text',
    showSortDirections: true,
    hideColumn: true,
  },
];

interface IManageAccountPageProps {
  title?: string;
}

export default function ManageAccountPage(props: IManageAccountPageProps) {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<TManageAccounts | undefined>(undefined);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [action, setAction] = useState<'enable' | 'disable' | null>(null);
  const [message, setMessage] = useState('');
  const [isInviteAccountsModalOpen, setIsInviteAccountsModalOpen] = useState(false);

  const { t } = useTranslation();
  const { mutateAsync: createAccount, isLoading: isCreating } = useCreateAccount();
  const { mutateAsync: updateAccount, isLoading: isUpdating } = useUpdateAccount();
  const { mutateAsync: scanAccount } = useScanAccount();

  const { toggle, ...manageAccountsFormProps } = useManageAccountsForm({
    onOk: async (data) => {
      if (isEdit && !!selectedAccount) {
        await updateAccount({
          id: selectedAccount.id,
          data: data,
        });
      } else {
        await createAccount(data);
      }

      setIsEdit(false);
      setSelectedAccount(undefined);
      toggle();
      dataTableProps.refetch();
    },
    onCancel: () => {
      setIsEdit(false);
      setSelectedAccount(undefined);
    },
    account: selectedAccount,
  });

  const actionItems: TActionItems<TManageAccounts>[] = [
    {
      label: 'Enable',
      key: 'enable',
      multiSelect: false,
      onClick: (item) => {
        setSelectedAccount(item);
        setAction('enable');
        setMessage('Are you sure you want to enable this account?');
        setIsConfirmationModalOpen(true);
      },
      show: (item) => (item.status as any) === 'Disabled',
    },
    {
      label: 'Disable',
      key: 'disable',
      multiSelect: false,
      onClick: (rec) => {
        setSelectedAccount(rec);
        setAction('disable');
        setMessage('Are you sure you want to disable this account?');
        setIsConfirmationModalOpen(true);
      },
      show: (rec) => (rec.status as any) === 'Enabled',
    },
    {
      label: 'View as Account Admin',
      key: 'view',
      multiSelect: false,
      onClick: (rec) => {
        window.open(`/impersonate?accountId=${rec.id}`, '_blank', 'rel=noopener noreferrer');
      },
    },
    {
      label: 'Scan',
      key: 'scan',
      multiSelect: false,
      onClick: (rec) => {
        scanAccount(rec.id);
      },
      show: (rec) => (rec.status as any) === 'Enabled',
    },
    {
      label: 'Edit Account',
      key: 'edit',
      multiSelect: false,
      onClick: (rec) => {
        setIsEdit(true);
        setSelectedAccount(rec);
        toggle();
      },
    },
  ];

  // const items: ActionDropdownProps<TManageAccounts>['items'] = [
  //   {
  //     label: 'Enable',
  //     key: 'enable',
  //     onClick: (rec) => {
  //       setSelectedAccount(rec);
  //       setAction('enable');
  //       setMessage('Are you sure you want to enable this account?');
  //       setIsConfirmationModalOpen(true);
  //     },
  //     show: (rec) => (rec.status as any) === 'Disabled',
  //   },
  //   {
  //     label: 'Disable',
  //     key: 'disable',
  //     onClick: (rec) => {
  //       setSelectedAccount(rec);
  //       setAction('disable');
  //       setMessage('Are you sure you want to disable this account?');
  //       setIsConfirmationModalOpen(true);
  //     },
  //     show: (rec) => (rec.status as any) === 'Enabled',
  //   },
  //   {
  //     label: 'View as Account Admin',
  //     key: 'view',
  //     onClick: (rec) => {
  //       window.open(`/impersonate?accountId=${rec.id}`, '_blank', 'rel=noopener noreferrer');
  //     },
  //   },
  //   {
  //     label: 'Scan',
  //     key: 'scan',
  //     onClick: (rec) => {
  //       scanAccount(rec.id);
  //     },
  //     show: (rec) => (rec.status as any) === 'Enabled',
  //   },
  //   {
  //     label: 'Edit Account',
  //     key: 'edit',
  //     onClick: (rec) => {
  //       setIsEdit(true);
  //       setSelectedAccount(rec);
  //       toggle();
  //     },
  //   },
  // ];

  // const tableCols: TDataColumns<TManageAccounts> = [
  //   ...manageAccountsTableCols,
  //   {
  //     title: 'Actions',
  //     key: 'action',
  //     align: 'center' as const,
  //     width: 100,
  //     render: (_, record) => {
  //       return <ActionDropdown items={items} record={record} />;
  //     },
  //   },
  // ];

  const dataTableProps = useDataTable<TManageAccounts>({
    model: 'accountExtended',
    columns: manageAccountsTableCols,
    stickyHeader: true,
    searchableColumns: ['name'],
    defaultOrder: {
      column: 'name',
      order: 'asc',
    },
    actionItems,
  });

  const handleModalClose = () => {
    setSelectedAccount(undefined);
    setAction(null);
    setMessage('');
    setIsConfirmationModalOpen(false);
  };

  const handleConfirm = async () => {
    if (action === 'enable') {
      if (!!selectedAccount) {
        await updateAccount({
          id: selectedAccount.id,
          data: {
            status: getEnumValue('AccountStatus', 'Enabled'),
          },
        });
        handleModalClose();
        dataTableProps.refetch();
      }
      return;
    }

    if (action === 'disable') {
      if (!!selectedAccount) {
        await updateAccount({
          id: selectedAccount.id,
          data: {
            status: getEnumValue('AccountStatus', 'Disabled'),
          },
        });
        handleModalClose();
        dataTableProps.refetch();
      }
      return;
    }
  };

  return (
    <>
      <PageTitle>Manage Accounts</PageTitle>
      <ComponentHeader
        title={t('common.manageAccounts')}
        actionButtons={
          <>
            <CreateAlertButton title="Create an account" onPress={toggle} />
            <CreateAlertButton title="Invite Account" onPress={() => setIsInviteAccountsModalOpen(true)} />
          </>
        }
      />
      <DataTable {...dataTableProps} />
      <ManageAccountsForm {...manageAccountsFormProps} isEdit={isEdit} isLoading={isCreating || isUpdating} />
      <ActionConfirmationModal
        open={isConfirmationModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message={message}
        isLoading={isUpdating}
      />
      <InviteAccountModal
        open={isInviteAccountsModalOpen}
        onCancel={() => setIsInviteAccountsModalOpen(false)}
        onInviteSuccess={dataTableProps.refetch}
      />
    </>
  );
}
