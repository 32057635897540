import { useEffect, useMemo } from 'react';

import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { TimeLine } from '@app/components/tables/AntdTableWrapper/components/Timeline';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';

interface IExemptionsProps<T extends { id: number | string }> {
  tableColumns: TDataColumns<T>;
  timelineRange?: {
    startDate: Date;
    endDate: Date;
  };
  hoveredDate?: string;
  setTimelineRange: (range: { startDate: Date; endDate: Date }) => void;
  setHoveredDate: (hoveredDate?: string) => void;
  setActiveDataTableProps: (dataTableProps: ReturnType<typeof useDataTable<T>>) => void;
  handleAdditionalDetailsClicked: (props: { changeLogDate: string; eventsText: string }) => void;
}

const Exemptions = <T extends { id: number | string }>({
  tableColumns,
  timelineRange,
  hoveredDate,
  setTimelineRange,
  setHoveredDate,
  setActiveDataTableProps,
  handleAdditionalDetailsClicked,
}: IExemptionsProps<T>) => {
  const customQueryKey = useMemo(() => {
    return `accountRecommendationHistory-odata date ge ${timelineRange?.startDate.toISOString()} and date le ${timelineRange?.endDate.toISOString()} and changeField eq 'IsExempted'`;
  }, [timelineRange]);

  const dataTableProps = useDataTable<T>({
    model: 'accountRecommendationHistory',
    hideExportToExcel: true,
    columns: tableColumns,
    searchableColumns: ['productName', 'findings'],
    defaultOrder: {
      column: 'date',
      order: 'desc',
    },
    limit: 50,
    showTimeline: true,
    customQueryKey: customQueryKey,
    constantFilter: `date ge ${timelineRange?.startDate.toISOString()} and date le ${timelineRange?.endDate.toISOString()} and changeField eq 'IsExempted'`,
    stickyHeader: true,
    onTimelineRangeChanged(newRange) {
      if (!newRange) return;

      setTimelineRange(newRange);
    },
    onRowMouseEnter(data, index) {
      setHoveredDate((data as any).date);
    },
    onRowMouseLeave(data, index) {
      setHoveredDate(undefined);
    },
  });

  useEffect(() => {
    setActiveDataTableProps(dataTableProps);
  }, [dataTableProps]);

  return (
    <DataTable {...dataTableProps}>
      {({ filterProps }) => {
        if (!filterProps?.timelineRange) {
          return <></>;
        }

        return (
          <TimeLine
            type="exemptions"
            additionalQueryParams="(markedExempted ne '0' or markedNonExempted ne '0')"
            handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
            startDate={filterProps?.timelineRange?.startDate}
            endDate={filterProps?.timelineRange?.endDate}
            hoveredDate={hoveredDate}
            setHoveredDate={setHoveredDate}
          />
        );
      }}
    </DataTable>
  );
};

export { Exemptions };
