import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TRecommendationHistory } from '@app/types/recommendationHistory';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import SeverityBadge from '@app/components/tables/AntdTableWrapper/components/SeverityBadge';
import EventDetailsModal from '@app/components/apps/change-logs/EventDetailsModal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { format, parse } from 'date-fns';
import { AllHistory } from '@app/components/apps/recommendation-history/AllHistory';
import { ConfigurationChanges } from '@app/components/apps/recommendation-history/ConfigurationChanges';
import { Assignments } from '@app/components/apps/recommendation-history/Assignments';
import { Exemptions } from '@app/components/apps/recommendation-history/Exemptions';
import { Tabs } from 'antd';
import { ChangeTypeBadge } from '@app/components/tables/AntdTableWrapper/components/StatusBadge';
import { Link } from 'react-router-dom';

export default function RecommendationHistory() {
  const { t } = useTranslation();
  const productOptions =
    useAppSelector((state) => state.app.products)?.map((opt) => ({
      key: opt.displayName,
      value: opt.displayName,
    })) || [];

  const [hoveredDate, setHoveredDate] = useState<string | undefined>();
  const [eventDetailsModalOpen, setEventDetailsModalOpen] = useState(false);
  const [changeLog, setChangeLog] = useState<{ changeLogDate: string; eventsText: string } | undefined>();
  const [timelineRange, setTimelineRange] = useState<{ startDate: Date; endDate: Date } | undefined>({
    startDate: moment(new Date()).subtract(90, 'days').toDate(),
    endDate: moment(new Date()).toDate(),
  });

  const [activeTabKey, setActiveTabKey] = useState('1');
  const [activeDataTableProps, setActiveDataTableProps] = useState<
    ReturnType<typeof useDataTable<TRecommendationHistory>> | undefined
  >();

  const handleAdditionalDetailsClicked = (props: { changeLogDate: string; eventsText: string }) => {
    setChangeLog(props);
    setEventDetailsModalOpen(true);
  };

  const handleAdditionalDetailsModalClose = () => {
    setEventDetailsModalOpen(false);
    setChangeLog(undefined);
  };

  const tableCols: TDataColumns<TRecommendationHistory> = [
    {
      title: 'Time',
      dataIndex: 'date',
      allowFiltering: true,
      type: 'datetime',
      render(value, record) {
        return <>{format(parse(record.date as any, 'dd/MM/yyyy HH:mm:ss', new Date()), 'dd/MM/yyyy HH:mm')}</>;
      },
      showSortDirections: true,
      width: '10vw',
    },
    {
      title: 'Findings',
      dataIndex: 'findings',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '16vw',
      render: (_, record) => {
        return (
          <Link to={`/recommendations/${record.recommendationId}`} target="_blank">
            {record.findings}
          </Link>
        );
      },
    },
    {
      title: 'Change Type',
      dataIndex: 'changeType',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      align: 'center',
      width: '8vw',
      render: (_, record) => {
        return <ChangeTypeBadge type={record.changeType as any}>{record.changeType}</ChangeTypeBadge>;
      },
    },
    {
      title: 'Change Description',
      dataIndex: 'changeDescription',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '16vw',
    },
    {
      title: 'Change Field',
      dataIndex: 'changeField',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '8vw',
      hideColumn: true,
    },
    {
      title: 'Snapshot Type',
      dataIndex: 'snapshotType',
      allowFiltering: false,
      showSortDirections: true,
      width: '8vw',
      allowDisplay: false,
      hideColumn: true,
    },
    // {
    //   title: 'Score',
    //   dataIndex: 'scoreValue',
    //   allowFiltering: true,
    //   type: 'number',
    //   showSortDirections: true,
    //   render(value, record) {
    //     return <>{`${record.scoreValue} pts`}</>;
    //   },
    //   width: '8vw',
    //   align: 'center',
    // },
    {
      title: 'Product',
      dataIndex: 'productName',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: productOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: '8vw',
      // render(_, record) {
      //   const productName = getProductLabel(record.productName);
      //   return <>{productName || record.productName}</>;
      // },
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationSeverity',
      showSortDirections: true,
      width: '6vw',
      hideColumn: true,

      render: (_, record) => {
        return (
          <SeverityBadge type={(record.severity as unknown as string).toLowerCase() as any}>
            {record.severity}
          </SeverityBadge>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   align: 'center' as const,
    //   render: (_, record) => {
    //     return <ActionDropdown items={items} record={record} />;
    //   },
    // },
  ];

  const tabs = [
    {
      label: 'All History',
      key: '1',
      children: (
        <AllHistory
          tableColumns={tableCols}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
        />
      ),
    },
    {
      label: 'Configuration Changes',
      key: '2',
      children: (
        <ConfigurationChanges
          tableColumns={tableCols}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
        />
      ),
    },
    {
      label: 'Assignments',
      key: '3',
      children: (
        <Assignments
          tableColumns={tableCols}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
        />
      ),
    },
    {
      label: 'Exemptions',
      key: '4',
      children: (
        <Exemptions
          tableColumns={tableCols}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!timelineRange) return;

    activeDataTableProps?.refetch();
  }, [timelineRange]);

  return (
    <>
      <PageTitle>Recommendation History</PageTitle>
      <ComponentHeader
        title={t('common.recommendationHistory')}
        subTitle="View the history of security recommendation changes, including compliance shifts and exemptions, to understand the evolution of your organization's security posture. Track the dates and details of each state change for comprehensive auditing."
        subTitleFullWidth={true}
      />
      <Tabs
        activeKey={activeTabKey}
        items={tabs}
        onChange={(activeKey) => setActiveTabKey(activeKey)}
        destroyInactiveTabPane={true}
      />
      {eventDetailsModalOpen && changeLog && (
        <EventDetailsModal {...changeLog} open={true} onCancel={handleAdditionalDetailsModalClose} />
      )}
    </>
  );
}
