import { useAppSelector } from './reduxHooks';
import { dashboardRoutes } from '../components/router/routes';

export const useGetRoutes = () => {
  const allowedScreens = useAppSelector((state) => state.app.allowedScreensAndActions?.screens);
  if (!allowedScreens) return;

  const filteredChildrenDashboarRoutes = dashboardRoutes.map((dr) => ({
    ...dr,
    children: dr.children?.filter((drc) => allowedScreens.includes(drc.key)),
  }));
  const filteredDashboardRoutes = filteredChildrenDashboarRoutes.filter((dr) => allowedScreens.includes(dr.key));

  return filteredDashboardRoutes;
};
