import { useMutation, useQuery, useQueryClient } from 'react-query';
import { httpApi } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';
import { TCreateGroup } from '@app/types/groupExtended';

type TUpdateGroupVariables = {
  id: number;
  payload: TCreateGroup;
};

const updateGroup = async ({ id, payload }: TUpdateGroupVariables) => {
  const response = await httpApi.patch(`/group/${id}`, payload);
  return response?.data;
};

export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateGroup,
    onSuccess: () => {
      queryClient.invalidateQueries('groupExtended-odata');
      notificationController.success({
        message: 'Group updated',
      });
    },
    onError: (error) => {
      notificationController.error({
        message: 'Something went wrong',
      });
    },
  });
};

const createGroup = async (payload: TCreateGroup) => {
  const response = await httpApi.post('/group', payload);
  return response?.data;
};

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createGroup,
    onSuccess: () => {
      queryClient.invalidateQueries('groupExtended-odata');
      notificationController.success({
        message: 'Group created',
      });
    },
    onError: (error) => {
      notificationController.error({
        message: 'Something went wrong',
      });
    },
  });
};

const deleteGroup = async (id: number) => {
  const response = await httpApi.delete(`/group/${id}`);
  if (response.status === 200) {
    notificationController.success({
      message: 'Group deleted',
    });
  }
  return response.data;
};

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteGroup,
    onSuccess: () => {
      queryClient.invalidateQueries('groupExtended-odata');
    },
  });
};

const getGroupPermissions = async () => {
  const response = await httpApi.get('/group/permissions');
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const useGetGroupPermissions = () => {
  return useQuery({
    queryKey: 'group-permissions',
    queryFn: getGroupPermissions,
  });
};

const getGroups = async () => {
  const response = await httpApi.get('/group');
  return response.data;
};

export const useGetGroups = () => {
  return useQuery({
    queryKey: 'groups',
    queryFn: getGroups,
  });
};
